var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "form-main" },
    [
      _c(
        "v-row",
        { attrs: { "no-gutters": "" } },
        [
          _c("v-col", { staticClass: "form-header text-center" }, [
            _c("div", [_vm._v("Dados do Tomador")])
          ])
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "form-row", attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            {
              class: _vm.style.colLeftClasses,
              attrs: {
                cols: _vm.style.colLeft,
                "align-self": _vm.style.alignSelf
              }
            },
            [_vm._v("CPF do Tomador")]
          ),
          _c(
            "v-col",
            {
              class: _vm.style.colRightClasses,
              attrs: {
                cols: _vm.style.colRight,
                "align-self": _vm.style.alignSelf
              }
            },
            [
              _c("input", {
                directives: [
                  {
                    name: "mask",
                    rawName: "v-mask",
                    value: _vm.masks.cpf,
                    expression: "masks.cpf"
                  },
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.cpf,
                    expression: "cpf"
                  }
                ],
                attrs: {
                  id: "cpf",
                  maxlength: "14",
                  name: "cpf",
                  size: "23",
                  type: "text"
                },
                domProps: { value: _vm.cpf },
                on: {
                  input: function($event) {
                    if ($event.target.composing) return
                    _vm.cpf = $event.target.value
                  }
                }
              })
            ]
          )
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "form-row", attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            {
              class: _vm.style.colLeftClasses,
              attrs: {
                cols: _vm.style.colLeft,
                "align-self": _vm.style.alignSelf
              }
            },
            [_vm._v("Nome do Tomador")]
          ),
          _c(
            "v-col",
            {
              class: _vm.style.colRightClasses,
              attrs: {
                cols: _vm.style.colRight,
                "align-self": _vm.style.alignSelf
              }
            },
            [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.nomeCompleto,
                    expression: "nomeCompleto"
                  }
                ],
                staticClass: "text-uppercase",
                attrs: {
                  id: "nomeCompleto",
                  maxlength: "70",
                  name: "nomeCompleto",
                  size: "45",
                  type: "text",
                  readonly: ""
                },
                domProps: { value: _vm.nomeCompleto },
                on: {
                  input: function($event) {
                    if ($event.target.composing) return
                    _vm.nomeCompleto = $event.target.value
                  }
                }
              })
            ]
          )
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "mt-3", attrs: { "no-gutters": "" } },
        [
          _c("v-col", { staticClass: "form-header text-center" }, [
            _c("div", [_vm._v("Produtos")])
          ])
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "form-row", attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            {
              class: _vm.style.colLeftClasses,
              attrs: { "align-self": _vm.style.alignSelf, cols: 1 }
            },
            [
              _c("div", { staticClass: "d-flex flex-row align-center" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.creditoImobiliario,
                      expression: "creditoImobiliario"
                    }
                  ],
                  attrs: {
                    id: "creditoImobiliario",
                    name: "creditoImobiliario",
                    type: "checkbox"
                  },
                  domProps: {
                    checked: Array.isArray(_vm.creditoImobiliario)
                      ? _vm._i(_vm.creditoImobiliario, null) > -1
                      : _vm.creditoImobiliario
                  },
                  on: {
                    change: function($event) {
                      var $$a = _vm.creditoImobiliario,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            (_vm.creditoImobiliario = $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            (_vm.creditoImobiliario = $$a
                              .slice(0, $$i)
                              .concat($$a.slice($$i + 1)))
                        }
                      } else {
                        _vm.creditoImobiliario = $$c
                      }
                    }
                  }
                })
              ])
            ]
          ),
          _c(
            "v-col",
            {
              class: _vm.style.colRightClasses,
              attrs: { "align-self": _vm.style.alignSelf, cols: 11 }
            },
            [
              _c("label", { attrs: { for: "creditoImobiliario" } }, [
                _vm._v("1 - Crédito Imobiliário")
              ])
            ]
          )
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "form-row", attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            {
              class: _vm.style.colLeftClasses,
              attrs: { "align-self": _vm.style.alignSelf, cols: 1 }
            },
            [
              _c("div", { staticClass: "d-flex flex-row align-center" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.consignacao,
                      expression: "consignacao"
                    }
                  ],
                  attrs: {
                    id: "consignacao",
                    name: "consignacao",
                    type: "checkbox"
                  },
                  domProps: {
                    checked: Array.isArray(_vm.consignacao)
                      ? _vm._i(_vm.consignacao, null) > -1
                      : _vm.consignacao
                  },
                  on: {
                    change: function($event) {
                      var $$a = _vm.consignacao,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 && (_vm.consignacao = $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            (_vm.consignacao = $$a
                              .slice(0, $$i)
                              .concat($$a.slice($$i + 1)))
                        }
                      } else {
                        _vm.consignacao = $$c
                      }
                    }
                  }
                })
              ])
            ]
          ),
          _c(
            "v-col",
            {
              class: _vm.style.colRightClasses,
              attrs: { "align-self": _vm.style.alignSelf, cols: 11 }
            },
            [
              _c("label", { attrs: { for: "consignacao" } }, [
                _vm._v("2 - Consignação")
              ])
            ]
          )
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "form-row", attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            {
              class: _vm.style.colLeftClasses,
              attrs: { "align-self": _vm.style.alignSelf, cols: 1 }
            },
            [
              _c("div", { staticClass: "d-flex flex-row align-center" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.cartaoCredito,
                      expression: "cartaoCredito"
                    }
                  ],
                  attrs: {
                    id: "cartaoCredito",
                    name: "cartaoCredito",
                    type: "checkbox"
                  },
                  domProps: {
                    checked: Array.isArray(_vm.cartaoCredito)
                      ? _vm._i(_vm.cartaoCredito, null) > -1
                      : _vm.cartaoCredito
                  },
                  on: {
                    change: function($event) {
                      var $$a = _vm.cartaoCredito,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 && (_vm.cartaoCredito = $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            (_vm.cartaoCredito = $$a
                              .slice(0, $$i)
                              .concat($$a.slice($$i + 1)))
                        }
                      } else {
                        _vm.cartaoCredito = $$c
                      }
                    }
                  }
                })
              ])
            ]
          ),
          _c(
            "v-col",
            {
              class: _vm.style.colRightClasses,
              attrs: { "align-self": _vm.style.alignSelf, cols: 11 }
            },
            [
              _c("label", { attrs: { for: "cartaoCredito" } }, [
                _vm._v("3 - Cartão de Crédito")
              ])
            ]
          )
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "form-row", attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            {
              class: _vm.style.colLeftClasses,
              attrs: { "align-self": _vm.style.alignSelf, cols: 1 }
            },
            [
              _c("div", { staticClass: "d-flex flex-row align-center" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.contaChequeAzul,
                      expression: "contaChequeAzul"
                    }
                  ],
                  attrs: {
                    id: "contaChequeAzul",
                    name: "contaChequeAzul",
                    type: "checkbox"
                  },
                  domProps: {
                    checked: Array.isArray(_vm.contaChequeAzul)
                      ? _vm._i(_vm.contaChequeAzul, null) > -1
                      : _vm.contaChequeAzul
                  },
                  on: {
                    change: function($event) {
                      var $$a = _vm.contaChequeAzul,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 && (_vm.contaChequeAzul = $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            (_vm.contaChequeAzul = $$a
                              .slice(0, $$i)
                              .concat($$a.slice($$i + 1)))
                        }
                      } else {
                        _vm.contaChequeAzul = $$c
                      }
                    }
                  }
                })
              ])
            ]
          ),
          _c(
            "v-col",
            {
              class: _vm.style.colRightClasses,
              attrs: { "align-self": _vm.style.alignSelf, cols: 11 }
            },
            [
              _c("label", { attrs: { for: "contaChequeAzul" } }, [
                _vm._v("4 - Conta com Cheque Azul(CROT)")
              ])
            ]
          )
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "form-row", attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            {
              class: _vm.style.colLeftClasses,
              attrs: { "align-self": _vm.style.alignSelf, cols: 1 }
            },
            [
              _c("div", { staticClass: "d-flex flex-row align-center" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.consigPortabilidade,
                      expression: "consigPortabilidade"
                    }
                  ],
                  attrs: {
                    id: "consigPortabilidade",
                    name: "consigPortabilidade",
                    type: "checkbox"
                  },
                  domProps: {
                    checked: Array.isArray(_vm.consigPortabilidade)
                      ? _vm._i(_vm.consigPortabilidade, null) > -1
                      : _vm.consigPortabilidade
                  },
                  on: {
                    change: function($event) {
                      var $$a = _vm.consigPortabilidade,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            (_vm.consigPortabilidade = $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            (_vm.consigPortabilidade = $$a
                              .slice(0, $$i)
                              .concat($$a.slice($$i + 1)))
                        }
                      } else {
                        _vm.consigPortabilidade = $$c
                      }
                    }
                  }
                })
              ])
            ]
          ),
          _c(
            "v-col",
            {
              class: _vm.style.colRightClasses,
              attrs: { "align-self": _vm.style.alignSelf, cols: 11 }
            },
            [
              _c("label", { attrs: { for: "consigPortabilidade" } }, [
                _vm._v("9 - Consignação Portabilidade")
              ])
            ]
          )
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "form-row", attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            {
              class: _vm.style.colLeftClasses,
              attrs: { "align-self": _vm.style.alignSelf, cols: 1 }
            },
            [
              _c("div", { staticClass: "d-flex flex-row align-center" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.chequeAzulIsolado,
                      expression: "chequeAzulIsolado"
                    }
                  ],
                  attrs: {
                    id: "chequeAzulIsolado",
                    name: "chequeAzulIsolado",
                    type: "checkbox"
                  },
                  domProps: {
                    checked: Array.isArray(_vm.chequeAzulIsolado)
                      ? _vm._i(_vm.chequeAzulIsolado, null) > -1
                      : _vm.chequeAzulIsolado
                  },
                  on: {
                    change: function($event) {
                      var $$a = _vm.chequeAzulIsolado,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 && (_vm.chequeAzulIsolado = $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            (_vm.chequeAzulIsolado = $$a
                              .slice(0, $$i)
                              .concat($$a.slice($$i + 1)))
                        }
                      } else {
                        _vm.chequeAzulIsolado = $$c
                      }
                    }
                  }
                })
              ])
            ]
          ),
          _c(
            "v-col",
            {
              class: _vm.style.colRightClasses,
              attrs: { "align-self": _vm.style.alignSelf, cols: 11 }
            },
            [
              _c("label", { attrs: { for: "chequeAzulIsolado" } }, [
                _vm._v("10 - Cheque Azul Isolado")
              ])
            ]
          )
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "form-row", attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            {
              class: _vm.style.colLeftClasses,
              attrs: { "align-self": _vm.style.alignSelf, cols: 1 }
            },
            [
              _c("div", { staticClass: "d-flex flex-row align-center" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.habitacao,
                      expression: "habitacao"
                    }
                  ],
                  attrs: {
                    id: "habitacao",
                    name: "habitacao",
                    type: "checkbox"
                  },
                  domProps: {
                    checked: Array.isArray(_vm.habitacao)
                      ? _vm._i(_vm.habitacao, null) > -1
                      : _vm.habitacao
                  },
                  on: {
                    change: function($event) {
                      var $$a = _vm.habitacao,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 && (_vm.habitacao = $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            (_vm.habitacao = $$a
                              .slice(0, $$i)
                              .concat($$a.slice($$i + 1)))
                        }
                      } else {
                        _vm.habitacao = $$c
                      }
                    }
                  }
                })
              ])
            ]
          ),
          _c(
            "v-col",
            {
              class: _vm.style.colRightClasses,
              attrs: { "align-self": _vm.style.alignSelf, cols: 11 }
            },
            [
              _c("label", { attrs: { for: "habitacao" } }, [
                _vm._v("11 - Habitação ")
              ])
            ]
          )
        ],
        1
      ),
      _c(
        "v-row",
        { attrs: { "no-gutters": "" } },
        [
          _c("v-col", { staticClass: "text-center my-3" }, [
            _c(
              "a",
              {
                staticClass: "btn-azul",
                attrs: { id: "btn-consultar" },
                on: { click: _vm.consultar }
              },
              [_vm._v("Consultar CPF")]
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }