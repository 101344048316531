import { render, staticRenderFns } from "./CaixaquiAvaliacaoRiscosDadosTomador.vue?vue&type=template&id=36742c24&scoped=true&"
import script from "./CaixaquiAvaliacaoRiscosDadosTomador.vue?vue&type=script&lang=js&"
export * from "./CaixaquiAvaliacaoRiscosDadosTomador.vue?vue&type=script&lang=js&"
import style0 from "../../../components/caixaqui/caixaqui-style.css?vue&type=style&index=0&id=36742c24&scoped=true&lang=css&"
import style1 from "./CaixaquiAvaliacaoRiscosDadosTomador.vue?vue&type=style&index=1&id=36742c24&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "36742c24",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('36742c24')) {
      api.createRecord('36742c24', component.options)
    } else {
      api.reload('36742c24', component.options)
    }
    module.hot.accept("./CaixaquiAvaliacaoRiscosDadosTomador.vue?vue&type=template&id=36742c24&scoped=true&", function () {
      api.rerender('36742c24', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/caixaqui/avaliacao-riscos/CaixaquiAvaliacaoRiscosDadosTomador.vue"
export default component.exports