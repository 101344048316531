<!--suppress HtmlFormInputWithoutLabel -->
<template>
  <v-container class="form-main">
    <v-row no-gutters>
      <v-col class="form-header text-center">
        <div>Dados do Tomador</div>
      </v-col>
    </v-row>
    <v-row no-gutters class="form-row">
      <v-col :cols="style.colLeft" :align-self="style.alignSelf" :class="style.colLeftClasses">CPF do Tomador</v-col>
      <v-col :cols="style.colRight" :align-self="style.alignSelf" :class="style.colRightClasses">
        <input id="cpf" maxlength="14" name="cpf" size="23" type="text" v-mask="masks.cpf" v-model="cpf" />
      </v-col>
    </v-row>
    <v-row no-gutters class="form-row">
      <v-col :cols="style.colLeft" :align-self="style.alignSelf" :class="style.colLeftClasses">Nome do Tomador</v-col>
      <v-col :cols="style.colRight" :align-self="style.alignSelf" :class="style.colRightClasses">
        <input
          class="text-uppercase"
          id="nomeCompleto"
          maxlength="70"
          name="nomeCompleto"
          size="45"
          type="text"
          v-model="nomeCompleto"
          readonly
        />
      </v-col>
    </v-row>

    <!-- Produtos -->
    <!-- Credito Imobiliario -->
    <v-row class="mt-3" no-gutters>
      <v-col class="form-header text-center">
        <div>Produtos</div>
      </v-col>
    </v-row>
    <v-row class="form-row" no-gutters>
      <v-col :align-self="style.alignSelf" :class="style.colLeftClasses" :cols="1">
        <div class="d-flex flex-row align-center">
          <input id="creditoImobiliario" name="creditoImobiliario" type="checkbox" v-model="creditoImobiliario" />
        </div>
      </v-col>
      <v-col :align-self="style.alignSelf" :class="style.colRightClasses" :cols="11"
        ><label for="creditoImobiliario">1 - Crédito Imobiliário</label></v-col
      >
    </v-row>
    <v-row class="form-row" no-gutters>
      <v-col :align-self="style.alignSelf" :class="style.colLeftClasses" :cols="1">
        <div class="d-flex flex-row align-center">
          <input id="consignacao" name="consignacao" type="checkbox" v-model="consignacao" />
        </div>
      </v-col>
      <v-col :align-self="style.alignSelf" :class="style.colRightClasses" :cols="11"
        ><label for="consignacao">2 - Consignação</label></v-col
      >
    </v-row>
    <v-row class="form-row" no-gutters>
      <v-col :align-self="style.alignSelf" :class="style.colLeftClasses" :cols="1">
        <div class="d-flex flex-row align-center">
          <input id="cartaoCredito" name="cartaoCredito" type="checkbox" v-model="cartaoCredito" />
        </div>
      </v-col>
      <v-col :align-self="style.alignSelf" :class="style.colRightClasses" :cols="11"
        ><label for="cartaoCredito">3 - Cartão de Crédito</label></v-col
      >
    </v-row>
    <v-row class="form-row" no-gutters>
      <v-col :align-self="style.alignSelf" :class="style.colLeftClasses" :cols="1">
        <div class="d-flex flex-row align-center">
          <input id="contaChequeAzul" name="contaChequeAzul" type="checkbox" v-model="contaChequeAzul" />
        </div>
      </v-col>
      <v-col :align-self="style.alignSelf" :class="style.colRightClasses" :cols="11"
        ><label for="contaChequeAzul">4 - Conta com Cheque Azul(CROT)</label></v-col
      >
    </v-row>
    <v-row class="form-row" no-gutters>
      <v-col :align-self="style.alignSelf" :class="style.colLeftClasses" :cols="1">
        <div class="d-flex flex-row align-center">
          <input id="consigPortabilidade" name="consigPortabilidade" type="checkbox" v-model="consigPortabilidade" />
        </div>
      </v-col>
      <v-col :align-self="style.alignSelf" :class="style.colRightClasses" :cols="11"
        ><label for="consigPortabilidade">9 - Consignação Portabilidade</label></v-col
      >
    </v-row>
    <v-row class="form-row" no-gutters>
      <v-col :align-self="style.alignSelf" :class="style.colLeftClasses" :cols="1">
        <div class="d-flex flex-row align-center">
          <input id="chequeAzulIsolado" name="chequeAzulIsolado" type="checkbox" v-model="chequeAzulIsolado" />
        </div>
      </v-col>
      <v-col :align-self="style.alignSelf" :class="style.colRightClasses" :cols="11"
        ><label for="chequeAzulIsolado">10 - Cheque Azul Isolado</label></v-col
      >
    </v-row>
  <v-row class="form-row" no-gutters>
      <v-col :align-self="style.alignSelf" :class="style.colLeftClasses" :cols="1">
        <div class="d-flex flex-row align-center">
          <input id="habitacao" name="habitacao" type="checkbox" v-model="habitacao" />
        </div>
      </v-col>
      <v-col :align-self="style.alignSelf" :class="style.colRightClasses" :cols="11"
        ><label for="habitacao">11 - Habitação </label></v-col
      >
    </v-row>
    <v-row no-gutters>
      <v-col class="text-center my-3">
        <a id="btn-consultar" @click="consultar" class="btn-azul">Consultar CPF</a>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
// noinspection ES6CheckImport
import _ from "lodash";
import { mapAvaliacaoRiscoFields } from "../../../store/modules/caixaqui-avaliacao-risco";
import { required } from "vuelidate/lib/validators";
import { isValid, requiredCheckIf } from "../../../validators";
import { mapActions, mapGetters } from "vuex";
import { operationTypes } from "../../../store/modules/controller";
import { OPTIONS } from "../../../config";

export default {
  name: "CaixaquiAvaliacaoRiscosDadosTomador",

  data: () => ({
    masks: OPTIONS.masks,
    style: {
      colLeft: 3,
      colRight: 9,
      alignSelf: "stretch",
      colLeftClasses: ["form-col-left", "d-flex", "flex-row", "align-center"],
      colRightClasses: ["form-col-right", "d-flex", "flex-row", "align-center"]
    },
    validationMessages: {
      cpf: {
        // TODO - Originalmente, a msg eh 'Campo obrigatório não preenchido.', mas essa mensagem complementar eh valida
        required: "Campo obrigatório não preenchido. Por favor preencha o CPF."
      },
      nomeCompleto: {
        // TODO - para cadastro de dossiê, o nome deverá ser preenchido.
        // TODO - No treinamento, este campo será carregado automaticamente após informado o cpf.
        required: "Campo obrigatório não preenchido. Por favor consultar."
      },
      creditoImobiliario: {
        required: "Campo obrigatório não preenchido. Por favor selecionar um produto."
      },
      consignacao: {
        required: "Campo obrigatório não preenchido. Por favor selecionar um produto."
      },
      cartaoCredito: {
        required: "Campo obrigatório não preenchido. Por favor selecionar um produto."
      },
      contaChequeAzul: {
        required: "Campo obrigatório não preenchido. Por favor selecionar um produto."
      },
      consigPortabilidade: {
        required: "Campo obrigatório não preenchido. Por favor selecionar um produto."
      },
      chequeAzulIsolado: {
        required: "Campo obrigatório não preenchido. Por favor selecionar um produto."
      },
      habitacao: {
        required: "Campo obrigatório não preenchido. Por favor selecionar um produto."
      }
    }
  }),
  validations() {
    return {
      cpf: { required },
      nomeCompleto: { required },
      creditoImobiliario: { required: requiredCheckIf(this.anyProdutoNotSelected) },
      consignacao: { required: requiredCheckIf(this.anyProdutoNotSelected) },
      cartaoCredito: { required: requiredCheckIf(this.anyProdutoNotSelected) },
      contaChequeAzul: { required: requiredCheckIf(this.anyProdutoNotSelected) },
      consigPortabilidade: { required: requiredCheckIf(this.anyProdutoNotSelected) },
      chequeAzulIsolado: { required: requiredCheckIf(this.anyProdutoNotSelected) },
      habitacao: { required: requiredCheckIf(this.anyProdutoNotSelected) }
    };
  },
  computed: {
    ...mapGetters("controller", ["operationType"]),
    ...mapAvaliacaoRiscoFields([
      "cpf",
      "nomeCompleto",
      "produtos.creditoImobiliario",
      "produtos.consignacao",
      "produtos.cartaoCredito",
      "produtos.contaChequeAzul",
      "produtos.consigPortabilidade",
      "produtos.chequeAzulIsolado",
      "produtos.habitacao"
    ]),
    anyProdutoNotSelected() {
      return !(
        this.creditoImobiliario ||
        this.consignacao ||
        this.cartaoCredito ||
        this.contaChequeAzul ||
        this.consigPortabilidade ||
        this.chequeAzulIsolado ||
          this.habitacao
      );
    }
  },
  methods: {
    ...mapActions("controller", ["findCliente"]),
    handleForwardEvent(to) {
      // AQUI PODEMOS VALIDAR QUALQUER COISA ANTES DE AVANCAR PARA A PROXIMA PAGINA
      if (to) {
        // this.$router.push(to);
        if (isValid(this.$v, this.validationMessages)) {
          this.$router.push(to);
        }
      }
    },
    handleBackwardEvent(to) {
      // AQUI PODEMOS VALIDAR QUALQUER COISA ANTES DE VOLTAR PARA A PAGINA
      if (this.operationType !== operationTypes.CADASTRO_DOSSIE && to) {
        this.$router.push(to);
      }
    },
    consultar() {
      if (this.cpf) {
        this.findCliente(this.cpf).then(cliente => {
          this.$log.debug(cliente);
          if (!_.isNil(cliente) && !_.isNil(cliente.nomeCompleto)) {
            this.nomeCompleto = cliente.nomeCompleto;
          } else {
            this.nomeCompleto = null;
            alert("O Cliente precisa estar previamente cadastrado.");
          }
        });
      } else {
        alert("Digite um cpf válido.");
      }
    }
  }
};
</script>

<style scoped src="../../../components/caixaqui/caixaqui-style.css"></style>
<style scoped>
#btn-consultar {
  padding: 3px 12px 3px 12px;
}

#cpf {
  width: 175px;
  margin-right: 4px;
}
</style>
